<template>
  <main class="entry-control-settings">
    <PageTitle
      title="進場模組設定"
      icon="chevron_left"
      hideBtn
      @iconClick="goBackToMenu"
    />

    <EntryControlTimeAndPriceSetting
      v-loading="loading"
      :configData="configData"
    />

    <EntryControlConsumerRightsOrderSetting
      v-loading="loading"
      :configData="configData"
      @refresh="init"
    />

    <EntryControlLinkSetting
      v-loading="loading"
      :configData="configData"
    />
  </main>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import EntryControlLinkSetting from './components/EntryControlLinkSetting.vue'
import EntryControlTimeAndPriceSetting from './components/EntryControlTimeAndPriceSetting'
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { GetEntryControlConfig } from '@/api/entryControl'
import { useShop } from '@/use/shop'
import EntryControlConsumerRightsOrderSetting from './components/EntryControlConsumerRightsOrderSetting.vue'

export default defineComponent({
  name: 'EntryControlSettings',
  components: {
    PageTitle,
    EntryControlLinkSetting,
    EntryControlTimeAndPriceSetting,
    EntryControlConsumerRightsOrderSetting,
  },
  setup () {
    const router = useRouter()
    const { shopId } = useShop()
    const configData = ref({})
    const loading = ref(false)

    const goBackToMenu = () => {
      router.push({
        name: 'Parameters',
      })
    }

    const init = async () => {
      // fetch data
      loading.value = true
      const [res, err] = await GetEntryControlConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        console.error(err)
        return
      }
      configData.value = res
    }

    onBeforeMount(async () => {
      await init()
    })

    return { goBackToMenu, configData, loading }
  },
})

</script>

<style lang="postcss" scoped>
.entry-control-settings {
  @apply flex flex-col gap-[30px];
}
</style>
