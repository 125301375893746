<template>
  <main class="section-block">
    <SectionTitle title="進場時段與收費模式" @edit="onEdit" />

    <div class="preview">
      所有門市不開放進場
    </div>
  </main>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'EntryControlTimeAndPriceSetting',
  components: {
    SectionTitle,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const displayData = computed(() => {
      return {}
    })

    const onEdit = () => {
      router.push({
        name: 'EditEntryControlTimeAndPriceSetting',
      })
    }
    return { displayData, onEdit }
  },
})

</script>

<style lang="postcss" scoped>
.preview {
  @apply text-sub text-gray-60;
}
</style>
